<template>
  <v-container fluid>
    <v-toolbar flat class="pb-10">
      <v-text-field
        class="d-print-none"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
      />
      <v-spacer/>
      <template v-if="platingMode">
        <h2>{{ platingMode }} Plating</h2>
      </template>
      <v-spacer/>
      <v-text-field :value="datesFormatted"
                    persistent-hint
                    hint="Meal Dates"
                    label="Select dates"
                    single-line
                    readonly
                    @click="datePickerDate=dates[0]; showDatePicker=true"
                    append-icon="mdi-calendar"
      />

      <v-dialog
        v-model="showDatePicker"
        ref="dialog"
        width="290px"
        persistent
      >
        <v-date-picker
          v-model="datePickerDate"
          no-title
          :allowed-dates="allowedDates"
          @close="closeDatePicker"
        ></v-date-picker>
        <v-btn @click="closeDatePicker">Close</v-btn>
      </v-dialog>
    </v-toolbar>
    <v-toolbar flat>
      <v-btn-toggle
        v-model="search">
        <v-btn :value="'1-C 2-C 3-C'">
          Simple Meals Only
        </v-btn>
      </v-btn-toggle>
      <v-spacer/>

      <v-btn-toggle v-model="rackedMealFilter">
        <v-btn value="racked">Racked Meals <span v-if="countRacked">({{ countRacked }})</span></v-btn>
      </v-btn-toggle>
      <v-spacer/>
      <v-btn-toggle v-model="plateTaskFilter">
        <!--        <v-btn value="ready-not-racked">Ready Not Racked <span v-if="countRacked">({{ countRacked }})</span></v-btn>-->
        <v-btn value="allergy-meals">Allergy Meals<span v-if="countAllergyCompleted">
          ({{ countAllergyCompleted }})</span>
        </v-btn>
        <v-btn value="main-meals">Main Meals<span v-if="countMainCompleted">
          ({{ countMainCompleted }})</span>
        </v-btn>

      </v-btn-toggle>
    </v-toolbar>
    <TaskPanel
      v-if="hasDates"
      team="plating"
      :dates="getDatesInRange(dateFrom,dateTo)"
      :search="search"
      :filter-fn="filterFunction"
      :sort-fn="sortFn"
      v-on:update:tasks="updateTasks"
      :data-as-table-override="dataAsTable"
    >
      <template v-slot:task-title="{ props: { task}}">
        <v-card-text>
          <v-row>
            <v-col>
              <b>{{ task.title }}</b>
              <br/>
              {{ task.description }}
            </v-col>
            <v-col class="text-right" v-if="task.source">
              <v-chip outlined v-if="task.source.mealShort">{{ task.source.mealShort }}</v-chip>
              <br/>
              <v-chip outlined color="red" v-if="task.source.rack">{{ task.source.rack }}</v-chip>
            </v-col>
          </v-row>
        </v-card-text>
      </template>
      <!--      /* eslint-disable-next-line vue/no-unused-vars */-->
      <template v-slot:task-text="{ props: {}}">
        <div></div>
      </template>
      <template v-slot:task-confirm-extra="{props: { confirmTask}}">
        <v-card-actions>
          <v-text-field
            label="Rack"
            v-model="confirmTask.source.rack"
            @blur="saveRack(confirmTask)"
          />
          <v-spacer/>
        </v-card-actions>

      </template>
    </TaskPanel>
    <v-alert v-if="!hasDates">
      Please select a production date
    </v-alert>
  </v-container>
</template>


<script>
import TaskPanel from "@/components/tasks/TaskPanel.vue";
import urlState from "@/router/urlState";
import {mapGetters} from "vuex";
import moment from "moment/moment";
import {
  dateFormatted,
  formatDuration,
  formatWeightWithUnits,
  getDatesInRange,
  getPlatingTimeEstimate,
  getProductionDays
} from "@/store/utils";
import api from "@/api";

export default {
  name: "PlatingTasks",
  components: {TaskPanel},
  mixins: [urlState],
  data() {
    return {
      dates: [],
      search: null,
      teamsSelected: null,
      showDatePicker: null,
      loading: null,
      datePickerDate: null,
      showGarnish: null,
      onlyGarnish: null,
      teams: ['allergy', 'main'],
      plateTaskFilter: [],
      rackedMealFilter: null,
      tasks: [],
      timeFormat: 'HH:mm:ss ddd',
    }
  },
  mounted() {
    this.syncToUrl({
      param: 'plateTaskFilter', urlParam: 'plating_filter', initFromRoute: true,
      // parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'taskFilter', urlParam: 'status', initFromRoute: true,
      // parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'dates', urlParam: 'dates', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'search', urlParam: 'search', initFromRoute: true,
      // parseCallback: (v) => v === 'true'
    });
    if (this.platingMode === 'Allergy') {
      this.rackedMealFilter = 'racked';
      this.plateTaskFilter = 'allergy-meals';
    }
  },
  methods: {
    getDatesInRange,
    sortFn(a, b) {
      // decreasing by meal count
      const bCount = (b.source && b.source.mealCount) || 0;
      const aCount = (a.source && a.source.mealCount) || 0;
      return bCount - aCount;
    },
    getComponents(componentIds) {
      return componentIds.map(this.getComponent).sort((a, b) => a.name.localeCompare(b.name));
    },
    getTimerId(id) {
      return `I${id}`;
    },
    dateFormatted,
    closeDatePicker() {
      this.showDatePicker = false;
      this.dates = getProductionDays(this.datePickerDate);
      console.log('setting dates', this.dates);
      return this.fetchData();
    },
    fetchData() {
      this.error = this.post = null
      this.loading = true
    },
    formatWeightWithUnits,
    allowedDates(val) {
      const notAllowed = {
        0: true,
        // 2: true,
        // 4: true
      };
      return !notAllowed[moment(val).day()];
    },
    saveTask(confirmTask) {
      this.$socket.emit('task:update', confirmTask);
    },
    saveRack(confirmTask) {
      console.log('save rack', confirmTask)
      const mealId = confirmTask.source.mealId;
      const platingRack = confirmTask.source.rack;
      api.put(`v2/meal/${mealId}`, {meal: {'plating_rack': platingRack}});
      // this lets other clients know to update the rack
      this.$socket.emit('task:update', confirmTask);

      if (confirmTask.source.allergy) {
        // also update main task
        const mainTask = this.tasks.find(t => t.source.mealId === mealId && !t.source.allergy);
        mainTask.source.rack = platingRack;
        this.$socket.emit('task:update', mainTask);
      } else {
        // also update allergy task
        const allergyTask = this.tasks.find(t => t.source.mealId === mealId && t.source.allergy);
        allergyTask.source.rack = platingRack;
        this.$socket.emit('task:update', allergyTask);
      }
    },
    filterFunction(t) {
      let isFiltered = false;
      if (this.rackedMealFilter) {
        isFiltered = isFiltered || !(t.source && t.source.rack);
      }
      if (this.plateTaskFilter === 'allergy-meals') {
        isFiltered = isFiltered || !(t.source && t.source.allergy);
      }
      if (this.plateTaskFilter === 'main-meals') {
        isFiltered = isFiltered || !(t.source && !t.source.allergy);
      }
      return !isFiltered;
    },
    updateTasks(tasks) {
      this.tasks = tasks;
    }
  },
  computed: {
    ...mapGetters(['getComponent', 'getMeal',]),
    datesFormatted() {
      const format = 'dddd MMMM D';
      if (!this.dateFrom) {
        return '';
      } else if (this.dateFrom === this.dateTo) {
        return `${moment(this.dateFrom).format(format)}`;
      } else {
        return `${moment(this.dateFrom).format(format)} - ${moment(this.dateTo).format(format)}`
      }
    },
    dateFrom() {
      return [...this.dates].sort()[0];
    },
    dateTo() {
      return [...this.dates].sort().reverse()[0];
    },
    hasDates() {
      return this.dates.length > 0;
    },
    countRacked() {
      return 0;
    },
    countAllergyCompleted() {
      return 0;
    },
    countMainCompleted() {
      return 0;
    },
    dataAsTable() {
      function getDuration(start, stop) {
        start = Date.parse(start);
        stop = Date.parse(stop);
        return stop - start;
      }

      const rows = [];
      for (const task of this.tasks) {
        let row;
        const {id, title, elapsed, description, source} = task;
        const {mealCount, componentCount} = source;
        const portionCount = mealCount * componentCount;
        const estimate = getPlatingTimeEstimate(mealCount);
        const differenceFromEstimate = elapsed ? formatDuration(elapsed - estimate, true) : '';
        if (!task.execution || task.execution.length === 0) {
          row = {
            taskId: id,
            title,
            mealCount,
            componentCount,
            portionCount,
            mealsPerMinute: '',
            portionsPerMinute: '',
            estimate: formatDuration(estimate, true),
            'differenceFromEstimate (mins)': differenceFromEstimate,
            totalDurationInMinutes: formatDuration(elapsed, true),
            people: '',
            peopleCount: '',
            durationInMinutes: '',
            start: '',
            stop: '',
            note: '',
            description,
          };
        } else {
          for (const execution of task.execution) {
            const {start, stop, team_members, note} = execution;
            let peopleCount = team_members.length;
            if (note && /\d+ people/.test(note)) {
              if (team_members[0] && team_members[0].name === 'Main Line') {
                peopleCount = note.split(' ')[0];
              }
            }
            const mealsPerMinute = (mealCount / (elapsed / (1000 * 60)));
            const portionsPerMinute = (portionCount / (elapsed / (1000 * 60)));
            row = {
              taskId: id,
              title,
              mealCount,
              componentCount,
              portionCount,
              mealsPerMinute: mealsPerMinute.toFixed(1),
              portionsPerMinute: portionsPerMinute.toFixed(1),
              mealsPerPersonPerMinute: (mealsPerMinute / peopleCount).toFixed(1),
              portionsPerPersonPerMinute: (portionsPerMinute / peopleCount).toFixed(1),
              estimate: formatDuration(estimate, true),
              'differenceFromEstimate (mins)': differenceFromEstimate,
              totalDurationInMinutes: formatDuration(elapsed, true),
              people: team_members.map(t => t.name).join(', '),
              peopleCount,
              durationInMinutes: formatDuration(getDuration(start, stop), true),
              start: dateFormatted(start, {formatString: this.timeFormat}),
              stop: dateFormatted(stop, {formatString: this.timeFormat}),
              note,
              description,
            };
          }
        }

        for (const e of Object.entries(task.source || {})) {
          row[e[0]] = e[1];
        }

        rows.push(row);

      }
      console.log('rows', rows);
      return rows;
    },
  },
  props: {
    platingMode: {type: String, default: 'Standard', required: false}
  }
}
</script>

<style scoped>

</style>